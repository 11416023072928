import styled from 'styled-components';

export const Container = styled.section`
  min-height: 100vh;

  background-color: ${({ theme }) => theme.colors.blue};

  align-self: center;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1.25rem;

  .logo img {
    margin: 0 auto 1rem auto;
  }

  & > div {
    width: 100%;
    max-width: 25rem;

    background-color: ${({ theme }) => theme.colors.light.color};
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    padding: 1.25rem;

    text-align: center;

    h4 {
      color: ${({ theme }) => theme.colors.blue};
    }

    .button {
      margin: 1.25rem auto 0 auto;
    }
  }
`;
