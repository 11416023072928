import { NextSeo } from 'next-seo';

import Template from 'templates/Default';

import Page404 from 'pages/Page404';

import seo from 'config/seo';

const MyPage404: React.FC = () => (
  <>
    <NextSeo title={`${seo().title} - Página não encontrada`} />

    <Template>
      <Page404 />
    </Template>
  </>
);

export default MyPage404;
