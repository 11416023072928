import Link from 'next/link';

import Template from 'templates/Default';

import routes from 'config/routes';

import Button from 'components/atom/Form/Button';
import Logo from 'components/atom/Logo';

import { Container } from './styles';

const Page404: React.FC = () => (
  <Template>
    <Container>
      <div>
        <Logo />

        <h4>Página não encontrada.</h4>
        <p>Não foi possível encontrar o que você estava procurando.</p>

        <Link href={routes.dashboard} passHref>
          <Button isATag theme="blue" text="Voltar para o site" />
        </Link>
      </div>
    </Container>
  </Template>
);

export default Page404;
